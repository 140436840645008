
import { downloadExportFile } from "@/helpers";
import * as selectOptions from "@/helpers/selectOptions";
import { capitalize, omit } from "lodash";
import Vue from "vue";
export default Vue.extend({
  name: "exporter",
  props: {
    defaultType: {
      type: String,
      required: false,
      default: ""
    },
    isLegacy: {
      type: Boolean,
      required: false,
      default: false
    },
    providedTypeFieldOptions: {
      type: Array,
      required: false
    }
  },
  data(): any {
    return {
      type: "",
      fields: [],
      typeFieldOptions: {
        users: selectOptions.userExportFieldOptions,
        counties: selectOptions.countiesExportFieldOptions,
        policyChangeRequests:
          selectOptions.policyChangeRequestsExportFieldOptions,
        payPlanChangeRequests: selectOptions.billingRequestsExportFieldOptions,
        billingChangeRequests: selectOptions.billingRequestsExportFieldOptions,
        policySign: selectOptions.signedPoliciesExportFieldOptions
      },
      exportAll: false
    };
  },
  methods: {
    async exportCountiesActionHandler(): Promise<void> {
      let query = this.exportAll
        ? {}
        : this.$store.state.list_view_module["filterQuery"] || {};

      //To be removed after INF filter query fix
      if (query.submittedOn_dateFilter) {
        query = omit(query, "submittedOn_dateFilter");
      }
      if (query.createdOn_dateFilter) {
        query = omit(query, "createdOn_dateFilter");
      }
      if (query.modifiedOn_dateFilter) {
        query = omit(query, "modifiedOn_dateFilter");
      }
      if (this.defaultType === "claims") {
        query["isLegacy"] = this.isLegacy + "";
      }
      downloadExportFile(
        {
          collection: this.type,
          query,
          fields: this.fields
        },
        `${capitalize(this.type)} - ${new Date().toISOString()}`
      );

      this.$emit("exported");
    },
    handleExportAll($event: boolean) {
      if ($event) {
        this.fields = this.columnFields.map(
          ({ value }: { value: string }) => value
        );
      }
    }
  },
  computed: {
    columnFields(): any {
      return this.type === "claims"
        ? this.providedTypeFieldOptions
        : this.typeFieldOptions[this.type];
    }
  },
  watch: {
    defaultType: {
      immediate: true,
      handler(val: string) {
        if (val.length > 0) {
          this.type = val;
          this.fields = this.columnFields.map((v: any) => v.value);
        }
      }
    }
  }
});
